import { useState, useEffect } from "react";

import { load_profile, load_invitation, login, signup, send_password_reset, password_reset } from "../services/ApiService";

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

const Login = function({ setPanel, onLogin }) {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(null);

    const submit = event => {
        event.preventDefault();
        login(username, password).then(response => {
            if(!!response && !response.error) onLogin(response);
            else if(!!response) setError(response.error)
        }).catch(setError);
    };

    return (
        <Card
            bg="dark"
            text="white"
            className="position-absolute top-50 start-50 translate-middle"
        >
            <Card.Body>
                <Card.Title className="text-center">
                    Login
                </Card.Title>
                <Form onSubmit={submit}>
                    <Form.Group controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            name="username"
                            value={username}
                            onChange={event => setUsername(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="password" className="mt-2">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <div className="d-grid mt-4">
                        {
                            !!error && <Alert variant="danger">{ error }</Alert>
                        }
                        <Button type="submit" variant="primary">
                            Login
                        </Button>
                    </div>
                    <div className="text-end">
                        <Button variant="link" size="sm" onClick={() => setPanel("password")}>
                            Forgot Password?
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
};
const Signup = function({ setPanel, invitedUser, onLogin }) {
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setpasswordConfirmation] = useState("");
	const [error, setError] = useState(null);

    useEffect(() => {
        setUsername(invitedUser.username);
        setEmail(invitedUser.email);
    }, [setUsername]);

    const submit = event => {
        event.preventDefault();

        if(!!password && (password !== passwordConfirmation)) {
            setError("Password and its confirmation do not match...");
            return;
        }

        signup(invitedUser.invitation_token, username, email, password).then(response => {
            if(!!response && !response.error) onLogin(response);
            else if(!!response) setError(response.error)
        }).catch(setError);
    };

    return (
        <Card
            bg="dark"
            text="white"
            className="position-absolute top-50 start-50 translate-middle"
        >
            <Card.Body>
                <Card.Title className="text-center">
                    Register
                </Card.Title>
                <Form onSubmit={submit}>
                    <Form.Group controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            name="username"
                            value={username}
                            onChange={event => setUsername(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="username" className="mt-2">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="password" className="mt-2">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="password" className="mt-2">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password-confirmation"
                            value={passwordConfirmation}
                            onChange={event => setpasswordConfirmation(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <div className="d-grid mt-4">
                        {
                            !!error && <Alert variant="danger">{ error }</Alert>
                        }
                        <Button type="submit" variant="primary">
                            Register
                        </Button>
                    </div>
                    <div className="text-end">
                        <Button variant="link" size="sm" onClick={() => setPanel("login")}>
                            Already a User?
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
};
const PasswordSendReset = function({ setPanel }) {
	const [username, setUsername] = useState("");
	const [message, setMessage] = useState(null);
	const [error, setError] = useState(null);

    const submit = event => {
        event.preventDefault();
        setMessage(null);
        setError(null);
        send_password_reset(username).then(response => {
            if(!!response && !!response.message) setMessage(response.message);
            else if(!!response && !!response.error) setError(response.error)
        }).catch(setError);
    };

    return (
        <Card
            bg="dark"
            text="white"
            className="position-absolute top-50 start-50 translate-middle"
        >
            <Card.Body>
                <Card.Title className="text-center">
                    Lost Password
                </Card.Title>
                <Form onSubmit={submit}>
                    <Form.Group controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            name="username"
                            value={username}
                            onChange={event => setUsername(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <div className="d-grid mt-4">
                        {
                            (!!error && <Alert variant="danger">{ error }</Alert>) ||
                            (!!message && <Alert variant="success">{ message }</Alert>)
                        }
                        <Button type="submit" variant="primary">
                            Send Password Reset Link
                        </Button>
                    </div>
                    <div className="text-end">
                        <Button variant="link" size="sm" onClick={() => setPanel("login")}>
                            Found Password?
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
};
const PasswordReset = function({ token, setPanel, onLogin }) {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState(null);
	const [error, setError] = useState(null);

    const submit = event => {
        event.preventDefault();
        setMessage(null);
        setError(null);
        password_reset(username, password, token).then(response => {
            if(!!response && !response.error) {
                window.location.search = "";
                onLogin(response);
            } else setError(response.error)
        }).catch(setError);
    };

    return (
        <Card
            bg="dark"
            text="white"
            className="position-absolute top-50 start-50 translate-middle"
        >
            <Card.Body>
                <Card.Title className="text-center">
                    Reset Password
                </Card.Title>
                <Form onSubmit={submit}>
                <Form.Group controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            name="username"
                            value={username}
                            onChange={event => setUsername(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="password" className="mt-2">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <div className="d-grid mt-4">
                        {
                            (!!error && <Alert variant="danger">{ error }</Alert>) ||
                            (!!message && <Alert variant="success">{ message }</Alert>)
                        }
                        <Button type="submit" variant="primary">
                            Reset Password
                        </Button>
                    </div>
                    <div className="text-end">
                        <Button variant="link" size="sm" onClick={() => setPanel("login")}>
                            Found Password?
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default function Authentication({ onLogin }) {
	const [loading, setLoading] = useState(true);
	const [invitationToken, setInvitationToken] = useState("");
	const [invitedUser, setInvitedUser] = useState({});
	const [panel, setPanel] = useState("login");
    /*
    1. load profile -> success, onLogin(true) ; false, show login screen
    2. support register (reusable, we'll use for profile editor) & reset password panels
    */
    useEffect(() => {
        const parameters = new URLSearchParams(window.location.search);
        load_profile().then(response => {
            if(!!response && !response.error) {
                setLoading(false)
                onLogin(response);
            } else {
                setInvitationToken(parameters.get("invitation"))
                if(invitationToken) {
                    load_invitation(invitationToken).then(response => {
                        if(response) setInvitedUser(response);
                        setLoading(false);
                        setPanel(invitedUser ? "signup" : "login");    
                    })
                } else {
                    setLoading(false)
                    setPanel(parameters.get("panel") || "login");
                }
            }
        });
    }, [setLoading, onLogin, invitationToken, setInvitationToken, setPanel]);

    const clear_and_set_panel = panel => {
        window.location.search = `?panel=${panel}`;
    };
    const parameters = new URLSearchParams(window.location.search);
    const token = parameters.get("reset-password");

    let content = null;
    if(token) content = <PasswordReset token={token} setPanel={clear_and_set_panel} onLogin={onLogin} />;
    else if(panel === "login") content = <Login setPanel={setPanel} onLogin={onLogin} />;
    else if(panel === "signup") content = <Signup setPanel={setPanel} invitedUser={invitedUser} onLogin={onLogin} />;
    else if(panel === "password") content = <PasswordSendReset setPanel={setPanel} />;

    return (
        loading ?
        <Spinner animation="grow" /> :
        <div id="authentication-panel-holder">
            { content }
        </div>
    );
}
