import { useState, useEffect } from "react";

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import {
    load_entity_options,
    load_records,
    load_record,
    save_record,
    autosuggest,
    delete_record
} from "../services/ApiService";

export default function ReceivedItems({ packingSlip, onToast, onScan, disabled }) {
	const [records, setRecords] = useState([]);
	const [itemsLoading, setItemsLoading] = useState(false);
	const [items, setItems] = useState([]);
	const [conditions, setConditions] = useState([]);
	const [scanning, setScanning] = useState(false);

    useEffect(() => {
        load_entity_options({ groups: ['conditions'] }).then(response => {
            if(response) {
                setConditions(response.conditions.records || response.conditions);
            }
        });
        load_records("", packingSlip.id || "", "received-items").then(response => {
            setRecords(response.records);
        });
    }, [packingSlip, setRecords, setConditions]);

    const change_field_value = (index, event) => {
        const field_id = event.target.name
        let value = event.target.value;
        let save_value;
        switch(event.target.type) {
            case "number":
                value = parseInt(value);
                break;
            case "typeahead":
                save_value = (value && value.length) ? value.map(option => option.id) : ""
                break;
            default:
                break;
        }
        if(save_value === undefined) save_value = value

        const new_records = [...records]
        new_records[index][field_id] = value;
        setRecords(new_records);
        if(new_records[index].pldid) save_record(new_records[index].pldid, { [field_id]: save_value }, "received-item").then(response => {
            new_records[index] = response;
            setRecords(new_records);
        });
    }
    const search_items = (received_item, query) => {
        setItemsLoading(true);
    
        autosuggest("item", query, (received_item.item && received_item.item.length) ? received_item.item[0] : "", "received-item").then(response => {
            setItems(response);
            setItemsLoading(false);
        })
    };
    const new_item_create = received_item => {
        const data = {...received_item};
        if(data.item && data.item.length && data.item[0].id) data.item[0] = data.item[0].id
        save_record("", data, "received-item").then(response => {
            if(response && response.airtable_id) {
                const index = records.findIndex(record => record.airtable_id === received_item.airtable_id)
                const new_records = [...records]
                if(index >= 0) new_records[index] = response;
                else new_records.push(response);
                setRecords(new_records);
            }
        });
    };
    const new_item_cancel = received_item => {
        const index = records.indexOf(received_item)
        if(index >= 0) {
            const new_records = [...records]
            new_records.splice(index, 1)
            setRecords(new_records);
        }
    };
    const add_item = (item_id = null) => {
        load_record('', 'received-item').then(response => {
            const record = response || {}
            record.packing_slip = [packingSlip.airtable_id];
            record.item = [];
            record.quantity = 1;
            record.condition = conditions[0].name;
            if(item_id) {
                record.item.push(item_id)
                new_item_create(record)
            } else {
                const new_records = [...records]
                new_records.unshift(record)
                setRecords(new_records);    
            }
        });
    };
    const delete_item = received_item => {
        const index = records.findIndex(record => record.airtable_id === received_item.airtable_id)
        if((index >= 0) && window.confirm("Are you sure you want to delete this item?")) {
            const new_records = [...records]
            new_records.splice(index, 1)
            delete_record(received_item.airtable_id, "received-item").then(() => {
                setRecords(new_records);
            });
        }
    };
    const add_toast = (variant, title, message = "") => {
        onToast({ variant, title, message });
    };
    const process_scan = event => {
        if(!!event.detail && (scanning !== event.detail)) {
            setScanning(event.detail);
            load_records("", event.detail, "received-items/product").then(response => {
                if(!!response && !!response.records && !!response.records.length) {
                    const product = response.records[0];
                    add_item(product.airtable_id);
                    add_toast("success", `${product.model || ""} (${product.pld_part_number})`);
                    setScanning(false);
                    // this.stop_scanning();
                    start_scanning();
                }
            }).catch(error => {
                add_toast("danger", error);
                setScanning(false);
                // this.stop_scanning();
                start_scanning();
            });
        } // else if(!event.detail && !scanning) this.stop_scanning();
    }
    const start_scanning = () => {
        onScan(process_scan);
    }

    return (
        <>
            {
                records &&
                (
                    <div>
                        <Table striped className="mt-5">
                            <thead>
                                <tr>
                                    <th className="w-75">
                                        Product
                                        <Button
                                            size="sm"
                                            onClick={() => add_item()}
                                        >
                                            +
                                        </Button>
                                        <Button size="sm" onClick={() => { start_scanning() }}>
                                            <img
                                                src="https://drive.google.com/uc?export=view&id=1QJTs0cvfqqVvj5Mu084LIXzA6aXPC_Ls"
                                                alt="Barcode Icon"
                                                style={{ height: "1em" }}
                                            />
                                        </Button>
                                    </th>
                                    <th className="w-auto">Qty</th>
                                    <th className="w-auto">Condition</th>
                                    <th className="w-auto">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    records.map((received_item, index) => (
                                        <tr
                                            key={received_item.airtable_id}
                                        >
                                            <td>
                                                <AsyncTypeahead
                                                    id={`item-${received_item.airtable_id}`}
                                                    isLoading={itemsLoading}
                                                    minLength={3}
                                                    options={items}
                                                    selected={received_item.item}
                                                    clearButton
                                                    onSearch={query => search_items(received_item, query)}
                                                    onChange={event => change_field_value(index, { target: { type: "typeahead", name: "item", value: event } })}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="number"
                                                    name="quantity"
                                                    value={received_item.quantity}
                                                    onChange={event => change_field_value(index, event)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Select
                                                    name="condition"
                                                    value={received_item.condition || ""}
                                                    disabled={disabled}
                                                    onChange={event => change_field_value(index, event)}
                                                >
                                                    {
                                                        conditions.map(condition => (
                                                            <option
                                                                value={condition.name}
                                                                key={condition.id}
                                                            >
                                                                {condition.name}
                                                            </option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </td>
                                            <td className="text-nowrap">
                                                {
                                                    !received_item.airtable_id &&
                                                    <div>
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => new_item_cancel(received_item)}
                                                        >
                                                            X
                                                        </Button>
                                                        <Button
                                                            variant="success"
                                                            size="sm"
                                                            onClick={() => new_item_create(received_item)}
                                                        >
                                                            √
                                                        </Button>
                                                    </div>
                                                }
                                                {
                                                    !!received_item.airtable_id &&
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={() => delete_item(received_item)}
                                                    >
                                                        X
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                )
            }
        </>
    );
};
