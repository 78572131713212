import React, { useState } from "react";

import Authentication from "./pages/Authentication";
import Root from "./pages/Root";
import Error from "./pages/Error";
import Home, { loader as homeLoader } from "./pages/Home";
import Product, { loader as productLoader } from "./pages/Product";
import PackingSlips, { loader as packingSlipsLoader } from "./pages/PackingSlips";
import PackingSlip, { loader as packingSlipLoader } from "./pages/PackingSlip";
import Users, { loader as usersLoader } from "./pages/Users";
import User, { loader as userLoader } from "./pages/User";
import Jobs, { loader as jobsLoader } from "./pages/Jobs";

import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider  
} from "react-router-dom";

export default function App() {
	const [loggedIn, setLoggedIn] = useState(false);

	const setLoggedOut = () => {
		setLoggedIn(null);
	};

	const userLoaderPoxy = (loader_data) => {
		loader_data.loggedIn = loggedIn;
		return userLoader(loader_data);
	};

	const router = createBrowserRouter(
		createRoutesFromElements(
			[
				<Route
					path="/"
					element={<Root profile={loggedIn} setLoggedOut={setLoggedOut} />}
					errorElement={Error}
				>
					<Route path="/" loader={homeLoader} element={<Home />} />
					<Route path="/product/:id?" loader={productLoader} element={<Product />} />,
					<Route path="/packing-slips" loader={packingSlipsLoader} element={<PackingSlips />} />,
					<Route path="/packing-slip/:id?" loader={packingSlipLoader} element={<PackingSlip />} />
					<Route path="/users" loader={usersLoader} element={<Users />} />,
					<Route path="/user/:id" loader={userLoaderPoxy} element={<User profile={loggedIn} />} />
					<Route path="/profile" loader={userLoaderPoxy} element={<User profile={loggedIn} setLoggedIn={setLoggedIn} />} />
					<Route path="/jobs" loader={jobsLoader} element={<Jobs />} />,
				</Route>
			]
		)
	);

	return (
		loggedIn ?
        <RouterProvider router={router} /> :
		<Authentication
			onLogin={setLoggedIn}
		/>
	);
};
