
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import PackingSlipEditor from './PackingSlipEditor';

import {
    load_record,
    save_record,
    attach_record_image
} from "../services/ApiService";
  
export default function NewPackingSlipDialog({ show, onHide }) {
    const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [packingSlip, setPackingSlip] = useState(null);
	const [saving, setSaving] = useState(false);

    const reset_shell_record = () => {
        setLoading(true);
        load_record('', 'packing-slip').then(response => {
            const record = response || {};
            record.date = (new Date()).toISOString().split("T")[0];
            setPackingSlip(record);
            setLoading(false);
        });
    }
    const change_field_value = (value, field) => {
        if(packingSlip) {
            const record = {
                ...packingSlip,
                [field]: value
            };
            setPackingSlip(record);
        }
    }
    const create = () => {
        setSaving(true);
        const { images, ...data } = packingSlip
        save_record("", data, "packing-slip").then(record => {
            if(record && record.id) {
                const record_id = record.id
                attach_record_image(record_id, { field: "images", image: images[0] }, "packing-slip").then(() => {
                    navigate(`/packing-slip/${record_id}`);
                    onHide();
                });
            }
        });
    }

    return (
        <Modal
            show={show}
            onShow={reset_shell_record}
            onHide={onHide}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Create a New Packing Slip
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {
                    (!!loading && <Spinner animation="grow" />) ||
                    (
                        !loading &&
                        !!packingSlip &&
                        <PackingSlipEditor
                            record={packingSlip}
                            disabled={saving}
                            onChange={change_field_value}
                        />
                    )
                }
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    disabled={saving}
                    onClick={onHide}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    disabled={saving}
                    onClick={create}
                >
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
