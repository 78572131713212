import { useState, useEffect } from "react";
import debounce from 'lodash.debounce';

import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import NewPackingSlipDialog from '../components/NewPackingSlipDialog';

import { logout } from "../services/ApiService";

const CONSTANTS = require("../constants.js");
const SCANNER = document.getElementById("scanner-root");
SCANNER.hidden = true;

let scan_handler = null;
const scan_done = () => {
	if(!SCANNER.hidden) {
		window.removeEventListener("scan-success", scanned);
		SCANNER.hidden = true;
	}
}
const scanned = event => {
	if(event.detail && scan_handler) scan_handler(event.detail);
	scan_done();
};
const scan = handler => {
    scan_handler = handler;
	SCANNER.hidden = false;
	window.addEventListener("scan-success", scanned);
	window.dispatchEvent(new Event("start-scanning"));
}

export default function Root({ profile, setLoggedOut }) {
    const location = useLocation();
    const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [receiving, setReceiving] = useState(true);

    useEffect(() => {
        setReceiving(false);
        scan_done();
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [setLoading, location]);

    const search_with_query = query => {
        if(query) navigate(`/?q=${query}`);
        else navigate('/', { replace: true });
    };
    const search = debounce(event => {
        search_with_query(event.target.value);
    }, 300);
    const do_logout = () => {
        logout().then(() => {
            setLoggedOut();
        })
    };

    return (
        !profile ?
        <></> : 
        <main
            className={loading ? "loading" : ""}
        >
            <Navbar bg="dark" variant="dark" expand="lg" sticky="top" className="d-print-none">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src="/logo192.png"
                            width="45"
                            height="45"
                            className="d-inline-block align-top"
                            alt="PrestigeL&D"
                        />
                    </Navbar.Brand>
                    <Navbar.Collapse>
                        <Nav>
                            <Link to="/" className="nav-link">Products</Link>
                            <Link to="/packing-slips" className="nav-link">Packing Slips</Link>
                            {
                                (profile.role >= CONSTANTS.USER_ROLE_ADMIN) ?
                                <Link to="/users" className="nav-link">Users</Link> :
                                <></>
                            }
                        </Nav>
                    </Navbar.Collapse>
                    <Nav className="justify-content-center my-2 my-md-0">
                        <Form className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                                onChange={event => search(event)}
                            />
                            <Button variant="primary" onClick={() => { scan(search_with_query) }}>
                                <img
                                    src="https://drive.google.com/uc?export=view&id=1QJTs0cvfqqVvj5Mu084LIXzA6aXPC_Ls"
                                    alt="Barcode Icon"
                                />
                            </Button>
                        </Form>
                        </Nav>
                    <Navbar.Collapse className="justify-content-end">
                        <Nav className="my-2 my-md-0">
                            <Link to="/product" className="btn btn-primary mx-1">New Product</Link>
                            <Button variant="primary" className="mx-1" onClick={() => setReceiving(true)}>Receive</Button>
                        </Nav>
                        <Nav className="my-2 my-md-0">
                            <Link to="/profile" >
                                <img
                                    src="https://storage.googleapis.com/pldos/images/extras/user.png"
                                    width="45"
                                    height="45"
                                    className="d-inline-block align-top"
                                    alt="Profile"
                                />
                            </Link>
                            <Button className="mx-1" onClick={do_logout}>
                                <i className="bi bi-box-arrow-right"></i>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </Container>
            </Navbar>
            <Container className="p-3">
                <Outlet context={[loading, setLoading, scan]}  />
            </Container>
            <NewPackingSlipDialog
                show={receiving}
                onHide={() => setReceiving(false)}
            />
        </main>
    );
}
  