import { useState } from "react";

import EditorPage from './EditorPage';
import PackingSlipEditor from '../components/PackingSlipEditor';
import ReceivedItems from '../components/ReceivedItems';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import {
    load_record,
    attach_record_image
} from "../services/ApiService";

const ENTITY = "packing-slip";

export async function loader({ params }) {
    const response = await load_record(params.id || "", ENTITY);
    return response;
}

const PageContent = function({ record, setRecord, save, scan }) {
	const [toasts, setToasts] = useState([]);

    const change_field_value = (value, field) => {
        if(!!record && !!record.id && (field === "images")) {
            return attach_record_image(record.id, { field, image: value[0] }, ENTITY).then(response => {
                setRecord(response);
            });
        }
        return save(field, value)
    }
    const add_toast = toast => {
        const new_toasts = [ ...toasts ]
        new_toasts.unshift({
            id: (new Date()).getTime(),
            variant: "",
            delay: 7000,
            ...toast
        })
        setToasts(new_toasts)
    }
    const remove_toast = toast =>{
        const new_toasts = [ ...toasts ]
        const index = new_toasts.indexOf(toast);
        if(index >= 0) {
            new_toasts.splice(index, 1)
            setToasts(new_toasts)
        }
    }

    return (
        <div>
            <PackingSlipEditor
                record={record}
                onChange={change_field_value}
            />
            <ReceivedItems
                packingSlip={record}
                onToast={add_toast}
                onScan={scan}
            />
            {
                !!toasts.length &&
                <ToastContainer position="bottom-end">
                    {
                        toasts.map(
                            toast => (
                                <Toast
                                    key={toast.id}
                                    bg={toast.variant}
                                    onClose={() => remove_toast(toast)}
                                    delay={toast.delay}
                                    autohide
                                >
                                    <Toast.Header>
                                        <strong className="me-auto">{ toast.title }</strong>
                                    </Toast.Header>
                                    {
                                        toast.message &&
                                        <Toast.Body>{ toast.message }</Toast.Body>
                                    }
                                </Toast>
                            )
                        )
                    }
                </ToastContainer>
            }
        </div>
    );
};

export default function PackingSlip() {
    return (
        <EditorPage
            entity={ENTITY}
            renderContentFunction={PageContent}
        />
    );
};
