import Container from 'react-bootstrap/Container';
import ListPage, { get_page_state } from './ListPage';

import { load_jobs } from "../services/ApiService";

const PAGE_STATE_ID = "packing-slips-state";
const page_state = get_page_state(PAGE_STATE_ID);

export async function loader() {
    const records = await load_jobs();
    debugger
    return { records, offset: "" };
}

const PageContent = function({ records }) {
    return (
        <>
            <Container>
                {
                    JSON.stringify(records)
                }
            </Container>
        </>
    );
};

export default function PackingSlips() {
    return (
        <ListPage
            title="Jobs"
            pageStateId={PAGE_STATE_ID}
            pageState={page_state}
            renderContentFunction={PageContent}
        />
    );
};