/*
We store share constants in a file in the React project (client-side) because React will not allow importing from outside its src folder
Furthermore, we use the module.exports mechanism (as opposed to the usual export default one in React) becuas eit is shared with the ES6 backend
*/

const CONSTANTS = {
    USER_ROLE_USER: 1,
    USER_ROLE_ADMIN: 10,
    USER_ROLE_DEVELOPER: 100,

    INVITATION_DELAYS: [
        { id: 3 * 60, name: "3h" },
        { id: 6 * 60, name: "6h" },
        { id: 12 * 60, name: "12h" },
        { id: 18 * 60, name: "18h" },
        { id: 24 * 60, name: "24h" }
    ]
};

CONSTANTS.USER_ROLES = [
    { id: CONSTANTS.USER_ROLE_USER, name: "User" },
    { id: CONSTANTS.USER_ROLE_ADMIN, name: "Administrator" },
    { id: CONSTANTS.USER_ROLE_DEVELOPER, name: "Developer" }
];

module.exports = CONSTANTS;