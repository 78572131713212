import { useState, useEffect } from "react";
import { useOutletContext, useLoaderData } from "react-router-dom";

import {
    save_record
} from "../services/ApiService";

import Container from 'react-bootstrap/Container';
import Spinner from "react-bootstrap/Spinner";

export default function EditorPage({ entity, renderContentFunction, pageContext }) {
    const [loading, setLoading, scan] = useOutletContext();
	const [pageRecord, setPageRecord] = useState({});
    const record = useLoaderData();

    useEffect(() => {
        setPageRecord(record);
        setLoading(false);
        window.scrollTo(0, 0);
    }, [record, setPageRecord, setLoading]);

    const save = (field_id, value) => {
        const record = {...pageRecord}
        record[field_id] = value;
        // optimistic update
        setPageRecord(record);
        if(record.airtable_id || record.id) {
            return save_record(record.airtable_id || record.id, { [field_id]: record[field_id] }, entity).then(response => {
                setPageRecord(response);
                return response;
            });
        }
        return Promise.resolve(record);
    }

    return (
        <>
            { loading ? <Spinner animation="grow" /> : <span className="d-none"></span> }
            <Container className={loading ? 'd-none' : 'd-block'}>
                { record ? renderContentFunction({ record: pageRecord, setRecord: setPageRecord, save, scan, pageContext }) : <div>No record found</div> }
            </Container>
        </>
    );
};
