import { useState } from "react";
import dayjs from 'dayjs';

import EditorPage from './EditorPage';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from "react-bootstrap/Alert";

import {
    load_record,
    send_password_reset
} from "../services/ApiService";

const CONSTANTS = require("../constants.js");
const ENTITY = "user";

export async function loader({ loggedIn, request, params }) {
    const url = new URL(request.url);
    if(url.pathname.match(/profile/)) return loggedIn
    const response = await load_record(params.id || "", ENTITY);
    return response;
}

const ReinviteUserDialog = function({ show, user, onSave, onHide }) {
	const [invitationExpiresIn, setInvitationExpiresIn] = useState(false);

    const reset_shell_record = () => {
        setInvitationExpiresIn(720);
    }
    const create = () => {
        onSave(invitationExpiresIn, "invitation_expires_in").then(() => {
            alert("Invitation email sent!");
            onHide();
        });
    }

    return (
        <Modal
            show={show}
            onShow={reset_shell_record}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Re-invite user
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {
                    (
                        !!user &&
                        <Form.Group controlId="invitationExpiresIn" className="mt-2">
                            <Form.Label>Invitation Expires In</Form.Label>
                            <Form.Select
                                name="invitationExpiresIn"
                                value={invitationExpiresIn}
                                onChange={event => setInvitationExpiresIn(event.target.value)}
                                required
                            >
                                {
                                    CONSTANTS.INVITATION_DELAYS.map(delay => (
                                        <option
                                            value={delay.id}
                                            key={delay.id}
                                        >
                                            {delay.name}
                                        </option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                    )
                }
            </Modal.Body>

            {
                !!user &&
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={onHide}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={create}
                    >
                        Re-Send Invitation
                    </Button>
                </Modal.Footer>
            }
        </Modal>
    );
};

const PageContent = function({ record, save, pageContext }) {
	const [inviting, setInviting] = useState(false);
	const [changingPassword, setChangingPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState(null);
	const [error, setError] = useState(null);

    const is_me = !!pageContext.profile && (pageContext.profile.id === record.id)
    const invitation_expired = !!record.invitation_expires_at && !dayjs(record.invitation_expires_at).isAfter()
    const submit = () => {};
    const save_value_for_field_id = (value, field_id) => {
        if(pageContext.profile.id === record.id) pageContext.profile[field_id] = value;
        return save(field_id, value);
    }
    const save_value = (event) => {
        save_value_for_field_id(event.target.value, event.target.name);
    }
    const save_password = () => {
        setChangingPassword(false);
        save_value_for_field_id(password, "password").then(() => {
            setPassword("");
        });
    };
    const sendPasswordReset = () => {
        setMessage(null);
        setError(null);
        send_password_reset(record.username).then(response => {
            if(!!response && !!response.message) setMessage(response.message);
            else if(!!response && !!response.error) setError(response.error)
        }).catch(setError);
    };

    return (
        <>
            <h1>User Profile</h1>

            <Form onSubmit={submit}>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                name="username"
                                value={record.username || ''}
                                onChange={save_value}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="username">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={record.email || ''}
                                onChange={save_value}
                                required
                            />
                        </Form.Group>
                    </Col>
                    {
                        (!!pageContext.profile && (pageContext.profile.role >= CONSTANTS.USER_ROLE_ADMIN)) ?
                        <Col md={6}>
                            <Form.Group controlId="role" className="mt-2">
                                <Form.Label>Role</Form.Label>
                                <Form.Select
                                    name="role"
                                    value={record.role}
                                    onChange={save_value}
                                    disabled={is_me}
                                    required
                                >
                                    {
                                        CONSTANTS.USER_ROLES.map(role => (
                                            <option
                                                value={role.id}
                                                key={role.id}
                                            >
                                                {role.name}
                                            </option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col> : 
                        <></>
                    }
                    <Col md={6}>
                        {
                            !record.invitation_token ?
                            <Form.Group controlId="password" className="mt-2">
                            {
                                changingPassword ?
                                <>
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={event => setPassword(event.target.value)}
                                        />
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => setChangingPassword(false)}
                                        >
                                            <i class="bi bi-x"></i>
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={save_password}
                                        >
                                            <i class="bi bi-check"></i>
                                        </Button>
                                    </InputGroup>
                                </> :
                                <>
                                    <Form.Label>&nbsp;</Form.Label>
                                    <div className="d-grid">
                                        {
                                            is_me ? 
                                            <Button
                                                variant="primary"
                                                onClick={() => setChangingPassword(true)}
                                            >
                                                Change my password
                                            </Button> : 
                                            <Button
                                                variant="primary"
                                                onClick={sendPasswordReset}
                                            >
                                                Send Password Reset
                                            </Button>
                                        }
                                    </div>
                                    {
                                        (!!error && <Alert variant="danger" className="mt-2">{ error }</Alert>) ||
                                        (!!message && <Alert variant="success" className="mt-2">{ message }</Alert>)
                                    }
                                </>
                            }
                            </Form.Group> :
                            <Form.Group controlId="invitation_token" className="mt-2">
                                <Form.Label
                                    className={invitation_expired ? "text-danger" : "" }
                                >
                                    Invited&nbsp;&bull;&nbsp;
                                    {
                                        invitation_expired ?
                                        <span>Expired since </span> :
                                        <span>Expires on </span>
                                    }
                                    { dayjs(record.invitation_expires_at).format("YYYY-MM-DD HH:mm") }
                                </Form.Label>
                                <div className="d-grid">
                                    {
                                        <Button
                                            variant="primary"
                                            onClick={() => setInviting(true)}
                                        >
                                            Resend invitation
                                        </Button>
                                    }
                                </div>
                            </Form.Group>
                        }
                    </Col>
                </Row>
            </Form>
            <ReinviteUserDialog
                show={inviting}
                user={record}
                onSave={save_value_for_field_id}
                onHide={() => setInviting(false)}
            />
        </>
    );
};

export default function User({ profile, setLoggedIn }) {
    return (
        <EditorPage
            entity={ENTITY}
            renderContentFunction={PageContent}
            pageContext={{ profile, setLoggedIn }}
        />
    );
};
