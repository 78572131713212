import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListPage, { get_page_state } from './ListPage';

import {
    load_records
} from "../services/ApiService";

const PAGE_STATE_ID = "home-state";
const page_state = get_page_state(PAGE_STATE_ID);

export async function loader({ request }) {
    const url = new URL(request.url);
    const query = url.searchParams.get("q")
    let response = await load_records(query ? "" : page_state.current_offset, query || page_state.query);
    if(!!response && !!response.error) response = await load_records("", query || page_state.query);
    return response;
}

const PageContent = function({ records, linkIdBuilder, onView }) {
    return (
        <>
            <Row xs={1} md={2} className="g-4">
                {
                    records && records.map(product => (
                        <Col key={product.id} lg={4} md={6}>
                            <Card onClick={() => onView(product)} className="product">
                                <Card.Body>
                                    <div className="ratio-box ratio-golden">
                                        <div>
                                            <img
                                                src={(product.images && product.images.length) ? product.images[0].url : 'https://storage.googleapis.com/pldos/images/extras/NoPic.jpeg'}
                                                alt={product.id}
                                            />
                                        </div>
                                    </div>
                                    <Card.Title>
                                        <Link id={linkIdBuilder(product)} to={`/product/${product.id}`}>{product.id}</Link>
                                    </Card.Title>
                                    <Card.Text>
                                        Manufacturer: {product.manufacturer_from_manufacturer_2 ? product.manufacturer_from_manufacturer_2.join(", ") : ""}<br />
                                        Series: {product.series || "" }<br />
                                        Model: {product.model || "" }<br />
                                        Product Description: {product.distributor_product_description ? product.distributor_product_description.substr(0,50) : "" }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </>
    );
};

export default function Home() {
    return (
        <ListPage
            title="Products"
            pageStateId={PAGE_STATE_ID}
            pageState={page_state}
            renderContentFunction={PageContent}
        />
    );
};
