import { Link } from "react-router-dom";

import Table from 'react-bootstrap/Table';
import ListPage, { get_page_state } from './ListPage';

import {
    load_records
} from "../services/ApiService";

const PAGE_STATE_ID = "packing-slips-state";
const page_state = get_page_state(PAGE_STATE_ID);

export async function loader({ request }) {
    const url = new URL(request.url);
    const query = url.searchParams.get("q")
    let response = await load_records(query ? "" : page_state.current_offset, query || page_state.query, "packing-slips");
    if(!!response && !!response.error) response = await load_records("", query || page_state.query, "packing-slips");
    return response;
}

const PageContent = function({ records, linkIdBuilder, onView }) {
    return (
        <>
            <Table striped>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Vendor</th>
                        <th>Items</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        records.map(packing_slip => (
                            <tr key={packing_slip.id} onClick={() => onView(packing_slip)}>
                                <td>{packing_slip.date}</td>
                                <td>
                                    <Link id={linkIdBuilder(packing_slip)} to={`/packing-slip/${packing_slip.id}`}>{packing_slip.name}</Link>
                                </td>
                                <td>{packing_slip.line_items_copy ? packing_slip.line_items_copy.length : ""}</td>
                                <td>{packing_slip.status}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    );
};

export default function PackingSlips() {
    return (
        <ListPage
            title="Packing Slips"
            pageStateId={PAGE_STATE_ID}
            pageState={page_state}
            renderContentFunction={PageContent}
        />
    );
};
