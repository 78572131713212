const receive_response = async (response) => {
    return response.json().then(data => {
        if(data && data.redirect) window.location = data.redirect;
        return data;    
    });
};

const domain = (process.env.NODE_ENV === 'development') ? "http://localhost:3001" : document.location.origin

const save_at_path = (path, changes, method = "POST") => {
    return fetch(`${domain}/api${path}`, {
        method,
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify(changes)
    }).then(receive_response).catch(error => {
        console.log(error)
    });
}
const attach_at_path = (path, data) => {
    const form_data = new FormData()
    Object.keys(data).forEach(key => {
        form_data.append(key, data[key])
    })

    return fetch(`${domain}/api${path}`, {
        method: "PATCH",
        credentials: "include",
        body: form_data
    }).then(receive_response).catch(error => {
        console.log(error)
    });
};
const delete_at_path = (path) => {
    return fetch(`${domain}/api${path}`, {
        method: "DELETE",
        credentials: "include"
    }).then(receive_response).catch(error => {
        console.log(error)
    });
}

export function load_profile() {
    return fetch(
        `${domain}/api/profile`, {
            credentials: "include"
        }
    ).then(receive_response).catch(error => {
        return null
    });
}
export function load_invitation(invitation) {
    return fetch(
        `${domain}/api/invitation/${invitation}`, {
            credentials: "include"
        }
    ).then(receive_response).catch(error => {
        return null
    });
}
export function login(username, password) {
    return save_at_path(
        "/login",
        { username, password }
    );
}
export function signup(token, username, email, password) {
    return save_at_path(
        "/signup",
        { token, username, email, password }
    );
}
export function logout() {
    return save_at_path(
        "/logout"
    );
}
export function send_password_reset(username) {
    return save_at_path(
        "/send-password-reset",
        { username }
    );
}
export function password_reset(username, password, token) {
    return save_at_path(
        "/password-reset",
        { username, password, token }
    );
}

export function load_records(page = "", search = "", entity = "") {
    if(entity) entity = `/${entity}`
    const url = new URL(`${domain}/api${entity}`)
    url.search = new URLSearchParams({ page, search }).toString();
    return fetch(url, { credentials: "include" }).then(receive_response).catch(error => {
        console.log(error)
    });
};
export function autosuggest(context, search, selection = "", entity = "") {
    if(entity) entity = `/${entity}`
    const url = new URL(`${domain}/api${entity}/autosuggest/${context}`)
    url.search = new URLSearchParams({ search, selection }).toString();
    return fetch(url, { credentials: "include" }).then(receive_response).catch(error => {
        console.log(error)
    });
};

export function load_entity_options(option_groups, entity = "") {
    if(entity) entity = `/${entity}`
    return fetch(`${domain}/api${entity}/options?${new URLSearchParams(option_groups)}`, { credentials: "include" }).then(receive_response).catch(error => {
        console.log(error)
    });
};
export function load_record(id, entity = "details") {
    if(entity) entity = `/${entity}`
    return fetch(`${domain}/api${entity}/${id}`, { credentials: "include" }).then(receive_response).catch(error => {
        console.log(error)
    });
};

export function save_record(record_id, changes, entity = "", method = "POST") {
    if(entity) entity = `/${entity}`
    return save_at_path(
        `${entity}/${record_id}`,
        changes,
        method
    );
}
export function attach_record_image(record_id, data, entity = "") {
    if(entity) entity = `/${entity}`
    return attach_at_path(`${entity}/${record_id}/images`, data);
};
export function delete_record(record_id, entity = "") {
    if(entity) entity = `/${entity}`
    return delete_at_path(`${entity}/${record_id}`);
}


export function save_product_barcode(product_id, data) {
     return save_at_path(
        `/${product_id}/barcode`,
        data,
        "PATCH"
    );
};
export function delete_product_barcode(product_id, barcode_id) {
    return delete_at_path(`/${product_id}/barcode/${barcode_id}`);
};



export function load_jobs(page = "", search = "") {
    const url = new URL(`${domain}/api/jobs`)
    url.search = new URLSearchParams({ page, search }).toString();
    return fetch(url, { credentials: "include" }).then(receive_response).catch(error => {
        console.log(error)
    });
};
