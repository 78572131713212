import { useState } from "react";

import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

export default function SmartImage({ src, ...props }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    if(!src) src = "https://storage.googleapis.com/pldos/images/extras/NoPic.jpeg"

    const image_loaded = () => {
        setLoading(false);
    };
    const image_invalid = () => {
        setError(true);
    };
    const css_classes = ["pldos-smart-image"];
    if(loading) css_classes.push("pldos-smart-image-loading");
    
    if(error) console.log(error);

    return (
        <span className={ css_classes.join(" ") }>
            <Image
                src={src}
                {...props}
                onLoad={image_loaded}
                onError={image_invalid}
            />
            { loading && <div><div><Spinner animation="grow" /></div></div> }
        </span>
    );
};
