import { useState, useEffect } from "react";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImageUpload from "./ImageUpload";
import SmartImage from "./SmartImage";

import {
    load_entity_options
} from "../services/ApiService";

export default function PackingSlipEditor({ record, disabled, onChange }) {
	const [suppliers, setSuppliers] = useState([]);
	const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        load_entity_options({ groups: ['suppliers', 'statuses'] }).then(response => {
            if(response) {
                setSuppliers(response.suppliers.records || response.suppliers);
                setStatuses(response.statuses.records || response.statuses);
            }
        });
    }, [setSuppliers, setStatuses]);

    const change_field_value = event => {
        let value = event.target.value;
        switch(event.target.type) {
            case "number":
                value = parseFloat(value);
                break;
            case "checkbox":
                value = event.target.checked;
                break;
            default:
                break;
        }
        return onChange(value, event.target.name)
    }
    const upload_image = (field, image) => {
        return onChange([image], field)
    }

    const supplier = (record.supplier && Array.isArray(record.supplier) && !!record.supplier.length) ? record.supplier[0] : record.supplier
    return (
        <div>
            <Form.Group
                as={Row}
                className="mb-3"
                controlId="supplier"
            >
                <Form.Label column sm="2">
                    Supplier
                </Form.Label>
                <Col sm="10">
                    <Form.Select
                        name="supplier"
                        value={supplier || ""}
                        disabled={disabled}
                        onChange={change_field_value}
                    >
                        {
                            suppliers.map(supplier => (
                                <option
                                    value={supplier.airtable_id}
                                    key={supplier.airtable_id}
                                >
                                    {supplier.id}
                                </option>
                            ))
                        }
                    </Form.Select>
                </Col>
            </Form.Group>
            <Row className="mb-3">
                <Col>
                    <Form.Group
                        as={Row}
                        controlId="date"
                    >
                        <Form.Label column sm="4">
                            Received
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control
                                type="date"
                                name="date"
                                value={record.date || ""}
                                disabled={disabled}
                                onChange={change_field_value}
                            />
                        </Col>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group
                        as={Row}
                        controlId="status"
                    >
                        <Form.Label column sm="4">
                            Status
                        </Form.Label>
                        <Col sm="8">
                            <Form.Select
                                name="status"
                                value={record.status || ""}
                                disabled={disabled}
                                onChange={change_field_value}
                            >
                                {
                                    statuses.map(status => (
                                        <option
                                            value={status.id}
                                            key={status.id}
                                        >
                                            {status.name}
                                        </option>
                                    ))
                                }
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group
                as={Row}
                className="mb-3"
            >
                <Form.Label column sm="2">
                    Packing Slip
                </Form.Label>
                <Col sm="10">
                    {
                        !!record.id &&
                        !!record.images && !!record.images.length && !!record.images[0] && record.images[0].url &&
                        <SmartImage
                                src={record.images[0].url}
                                alt="Packing Slip"
                        />
                    }
                    <ImageUpload
                        name="images"
                        multiple={ false }
                        label="Click here or drop image file"
                        handleChange={file => upload_image("images", file) }
                        disabled={disabled}
                    />
                </Col>
            </Form.Group>
            <Form.Group
                as={Row}
                className="mb-3"
                controlId="notes"
            >
                <Form.Label column sm="2">
                    Notes
                </Form.Label>
                <Col sm="10">
                    <Form.Control
                        as="textarea"
                        name="notes"
                        value={record.notes}
                        disabled={disabled}
                        onChange={change_field_value}
                    />
                </Col>
            </Form.Group>
        </div>
    );
};
