import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import EditorPage from './EditorPage';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import SmartImage from "../components/SmartImage";
import ImageUpload from "../components/ImageUpload";
import { Typeahead, TypeaheadInputMulti, Token } from 'react-bootstrap-typeahead';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';

import {
    load_record,
    load_entity_options,
    save_product_barcode,
    delete_product_barcode,
    attach_record_image
} from "../services/ApiService";
import { DrawCode39Barcode } from "../services/BarcodeService";

const ENTITY = "";

export async function loader({ params }) {
    const response = await load_record(params.id || "");
    return response;
}

const PageContent = function({ record, setRecord, save, scan }) {
	const [suppliers, setSuppliers] = useState([]);
	const [manufacturers, setManufacturers] = useState([]);
    const [editing, setEditing] = useState(false);
	const [imageUrl, setImageUrl] = useState("");
	const [barcode, setBarcode] = useState(null);
	const [galleryIndex, setGalleryIndex] = useState(0);
    const [viewingGallery, setViewingGallery] = useState(false);

    useEffect(() => {
        load_entity_options({ groups: ['suppliers', 'manufacturers'] }).then(response => {
            if(response) {
                setSuppliers(response.suppliers.records || response.suppliers);
                setManufacturers(response.manufacturers.records || response.manufacturers);
            }
        });
        if(!record.airtable_id) setEditing(true);
    }, [record, setSuppliers, setManufacturers, setEditing]);

    const edit = event => {
        if(event) event.preventDefault();
        setEditing(true);
    }
    const edit_stop = event => {
        if(event) event.preventDefault();
        setEditing(false);
    }
    const print = () => {
        window.print();
    }

    const main_image = record && record.images && record.images.length ? record.images[0] : null
    const main_image_url = main_image ? main_image.url : null
    let addl_images = record && record.images ? [...record.images] : []
    addl_images.shift()
    const gallery = record && record.images ? [...record.images] : []
    const change_field_value = event => {
        let value = event.target.value;
        switch(event.target.type) {
            case "number":
                value = parseFloat(value);
                break;
            case "checkbox":
                value = event.target.checked;
                break;
            default:
                break;
        }
        return save(event.target.name, value)
    }
    const selected_options = (options, selected_ids) => {
        if(!options || !Array.isArray(options) || !selected_ids) return []
        return options.filter(option => {
            return selected_ids.indexOf(option.airtable_id) >= 0
        })
    };
    const select_options = (field_id, selected) => {
        return save(field_id, selected.map(option => option.airtable_id || option));
    }
    const view = index => {
        setGalleryIndex(index);
        setViewingGallery(index >= 0);
    }
    const change_image_url_value = event => {
        setImageUrl(event.target.value);
    }
    const upload_image = (field, image) => {
        attach_record_image(record.id, { field, image }).then(record => {
            setRecord(record);
        });
    }
    const add_image = (field) => {
        if(imageUrl) {
            const images = [...record[field]]
            images.push({ url: imageUrl })
            save(field, images);
        }
    }
    const delete_image = (field, image) => {
        const index = record[field].findIndex(existing_image => existing_image.id === image.id)
        if((index >= 0) && window.confirm("Are you sure you want to delete this image?")) {
            const images = [...record[field]]
            images.splice(index, 1)
            save(field, images);
        }
    }

    const barcodes = record ? (record.barcodes_text || []).filter(barcode => !!barcode) : [];
    const save_barcode = value => {
        save_product_barcode(
            record.id,
            {
                product: [record.airtable_id],
                barcode: value
            }
        ).then(record => {
            setRecord(record);
            cancel_new_barcode();
        });
    }
    const new_barcode = (scanner = false) => {
        if(scanner) scan(save_barcode);
        else setBarcode("");
    }
    const change_barcode_value = event => {
        setBarcode(event.target.value);
    }
    const cancel_new_barcode = event => {
        if(event) event.preventDefault();
        setBarcode(null);
    }
    const create_barcode = event => {
        if(event) event.preventDefault();
        save_barcode(barcode);
    }
    const delete_barcode = (barcode) => {
        const index = record.barcodes_text.indexOf(barcode)
        if((index >= 0) && (index <= (record.alternate_barcodes.length - 1)) && window.confirm(`Are you sure you want to delete the barcode "${barcode}"?`)) {
            delete_product_barcode(record.id, record.alternate_barcodes[index]).then(record => {
                setRecord(record);
            });
        }
    }

    return (
        <>
            <Row>
                <Col lg={4} md={6} className="product-images text-center">
                    <SmartImage
                        src={main_image_url}
                        alt="Current Main Product"
                        onClick={() => { view(0) }}
                    />
                    {
                        editing &&
                        <Button variant="danger" size="sm" className="delete-image-button" onClick={() => delete_image("images", main_image)}>X</Button>
                    }
                    <Container>
                        <Row>
                            {
                                addl_images.map((image, index) => (
                                    <Col key={image.id} lg={3} md={4}>
                                        <SmartImage
                                            fluid
                                            src={image.url}
                                            alt={image.filename}
                                            className="m-2"
                                            onClick={() => { view(index + 1) }}
                                        />
                                        {
                                            editing &&
                                            <Button variant="danger" size="sm" className="delete-image-button" onClick={() => delete_image("images", image)}>X</Button>
                                        }
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
                    {
                        record && record.id && editing &&
                        <div className="mt-4">
                            <ImageUpload
                                name="images"
                                multiple={ false }
                                label="Click here or drop image file"
                                handleChange={file => upload_image("images", file) }
                            />
                            <div className="fs-6 text-uppercase text-muted text-center my-2">OR</div>
                            <InputGroup>
                                <Form.Control
                                    placeholder="New Image URL"
                                    aria-label="Enter an image URL"
                                    value={imageUrl}
                                    onChange={change_image_url_value}
                                />
                                <Button
                                    variant="outline-info"
                                    onClick={() => add_image("images")}
                                >
                                    Add
                                </Button>
                            </InputGroup>
                        </div>
                    }
                    {
                        record.pld_barcode && 
                        <div className="mt-4">
                            <span
                                dangerouslySetInnerHTML={{ __html: DrawCode39Barcode(record.pld_barcode, 0) }}
                                className="pldos-barcode"
                            ></span>
                        </div>
                    }
                </Col>
                <Col lg={8} md={6}>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Part Number</th>
                                <th>
                                    { record.id }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>IDN #</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Control
                                            type="text"
                                            name="idn_"
                                            value={record.idn_}
                                            onChange={change_field_value}
                                        />
                                        :
                                        record.idn_
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Model</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Control
                                            type="text"
                                            name="model"
                                            value={record.model}
                                            onChange={change_field_value}
                                        />
                                        :
                                        record.model
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Type</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Control
                                            type="text"
                                            name="type_3"
                                            value={record.type_3}
                                            onChange={change_field_value}
                                        />
                                        :
                                        record.type_3
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>SKU</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Control
                                            type="text"
                                            name="sku"
                                            value={record.sku}
                                            onChange={change_field_value}
                                        />
                                        :
                                        record.sku
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Price</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Control
                                            type="number"
                                            name="unit_price"
                                            value={record.unit_price}
                                            onChange={change_field_value}
                                        />
                                        :
                                        record.unit_price
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Member Price</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Control
                                            type="number"
                                            name="member_price"
                                            value={record.member_price}
                                            onChange={change_field_value}
                                        />
                                        :
                                        record.member_price
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Distributor's Description</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Control
                                            type="text"
                                            name="distributor_product_description"
                                            value={record.distributor_product_description}
                                            onChange={change_field_value}
                                        />
                                        :
                                        record.distributor_product_description
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>In Accounting?</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Check
                                            type="checkbox"
                                            name="in_accounting"
                                            checked={!!record.in_accounting}
                                            onChange={change_field_value}
                                        />
                                        :
                                        (record.in_accounting ? 'Yes' : 'No')
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Generic?</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Check
                                            type="checkbox"
                                            name="is_generic"
                                            checked={!!record.is_generic}
                                            onChange={change_field_value}
                                        />
                                        :
                                        (record.is_generic ? 'Yes' : 'No')
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>In Inventory?</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Check
                                            type="checkbox"
                                            name="is_in_inventory"
                                            checked={!!record.is_in_inventory}
                                            onChange={change_field_value}
                                        />
                                        :
                                        (record.is_in_inventory ? 'Yes' : 'No')
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Assembly?</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Check
                                            type="checkbox"
                                            name="is_assembly"
                                            checked={!!record.is_assembly}
                                            onChange={change_field_value}
                                        />
                                        :
                                        (record.is_assembly ? 'Yes' : 'No')
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Active?</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Check
                                            type="checkbox"
                                            name="active"
                                            checked={!!record.active}
                                            onChange={change_field_value}
                                        />
                                        :
                                        (record.active ? 'Yes' : 'No')
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Auto Tender?</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Check
                                            type="checkbox"
                                            name="auto_tender"
                                            checked={!!record.auto_tender}
                                            onChange={change_field_value}
                                        />
                                        :
                                        (record.auto_tender ? 'Yes' : 'No')
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Auto Order?</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Check
                                            type="checkbox"
                                            name="auto_order"
                                            checked={!!record.auto_order}
                                            onChange={change_field_value}
                                        />
                                        :
                                        (record.auto_order ? 'Yes' : 'No')
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Category</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Control
                                            type="text"
                                            name="category"
                                            value={record.category}
                                            onChange={change_field_value}
                                        />
                                        :
                                        record.category
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Unit Cost</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Control
                                            type="number"
                                            name="unit_cost"
                                            value={record.unit_cost}
                                            onChange={change_field_value}
                                        />
                                        :
                                        record.unit_cost
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Description</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Control
                                            type="text"
                                            name="pld_product_description"
                                            value={record.pld_product_description}
                                            onChange={change_field_value}
                                        />
                                        :
                                        record.pld_product_description
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Cost Entered On</th>
                                <td>
                                    {
                                        editing ? 
                                        <Form.Control
                                            type="date"
                                            name="cost_date_entered"
                                            value={record.cost_date_entered}
                                            onChange={change_field_value}
                                        />
                                        :
                                        record.cost_date_entered
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Supplier(s)</th>
                                <td>
                                    {
                                        editing ? 
                                        <Typeahead
                                            id="suppliers"
                                            multiple
                                            labelKey="name"
                                            onChange={selected => select_options("suppliers", selected)}
                                            options={suppliers || []}
                                            renderInput={(input_props, props) => (
                                                <TypeaheadInputMulti {...input_props} selected={record.suppliers}>
                                                    {
                                                        selected_options(suppliers, record.suppliers).map(
                                                            option => (
                                                                <Token
                                                                    key={option.airtable_id}
                                                                    option={option}
                                                                    onRemove={() => { props.onRemove(option.airtable_id) }}
                                                                >
                                                                    {option.name}
                                                                </Token>
                                                            )
                                                        )
                                                    }
                                                </TypeaheadInputMulti>
                                            )}
                                            selected={record.suppliers}
                                        />
                                        :
                                        (selected_options(suppliers, record.suppliers) || []).map(option => option.name).join(", ")
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Manufacturer(s)</th>
                                <td>
                                    {
                                        editing ? 
                                        <Typeahead
                                            id="manufacturers"
                                            multiple
                                            labelKey="code"
                                            onChange={selected => select_options("manufacturer", selected)}
                                            options={manufacturers || []}
                                            renderInput={(input_props, props) => (
                                                <TypeaheadInputMulti {...input_props} selected={record.manufacturer}>
                                                    {
                                                        selected_options(manufacturers, record.manufacturer).map(
                                                            option => (
                                                                <Token
                                                                    key={option.airtable_id}
                                                                    option={option.airtable_id}
                                                                    onRemove={() => { props.onRemove(option.airtable_id) }}
                                                                >
                                                                    {option.code}
                                                                </Token>
                                                            )
                                                        )
                                                    }
                                                </TypeaheadInputMulti>
                                            )}
                                            selected={record.manufacturer}
                                        />
                                        :
                                        (selected_options(manufacturers, record.manufacturer) || []).map(option => option.code).join(", ")
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <section className="pldos-boxed mt-3 p-3">
                <h4>Alternate Barcodes</h4>

                <ul className="list-unstyled pldos-alt-barcodes">
                    {
                        barcodes.map(barcode => (
                            <li key={barcode} className="m-2">
                                <span
                                    dangerouslySetInnerHTML={{ __html: DrawCode39Barcode(barcode, 0) }}
                                    className="pldos-barcode"
                                ></span>
                                {editing && <Button variant="danger" size="sm" onClick={() => delete_barcode(barcode)}>X</Button>}
                            </li>
                        ))
                    }
                </ul>
                {
                        editing && (barcode === null) &&
                        <div className="text-center m-2">
                            <Button
                                variant="secondary"
                                size="sm"
                                className="mx-2"
                                onClick={() => new_barcode(true)}
                            >
                                Read New
                            </Button>
                            <Button
                                variant="secondary"
                                size="sm"
                                className="mx-2"
                                onClick={() => new_barcode()}
                            >
                                Type New
                            </Button>
                        </div>
                    }
                
                {
                    (barcode !== null) && 
                    <Form className="pldos-barcode-editor mt-4">
                        <Form.Group className="mb-3" controlId="barcode">
                            <Form.Label>New Barcode:</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={change_barcode_value}
                            />
                        </Form.Group>

                        <Button variant="danger" onClick={cancel_new_barcode}>Cancel</Button>
                        <Button variant="success" type="submit" onClick={create_barcode}>Create</Button>
                    </Form>
                }
            </section>

            <section className="text-center mt-8 p-3">
                {
                    !!record && !!record.airtable_id && !editing &&
                    <Button variant="primary" onClick={edit}>Edit</Button>
                }
                {
                    !!record && !!record.airtable_id && !!editing &&
                    <Button variant="secondary" onClick={edit_stop}>Done</Button>
                }
                {
                    !!record && !record.airtable_id && !!editing &&
                    <Link to="/" className="btn btn-secondary">Cancel</Link>
                }
                {
                    !!record && !record.airtable_id && !!editing &&
                    <Button variant="primary">Save</Button>
                }
                <Button variant="secondary" onClick={print}>Print</Button>
            </section>
            <Modal show={viewingGallery} fullscreen={true} onHide={() => view(-1)} className="lightbox">
                <Modal.Header closeButton closeVariant="white"></Modal.Header>
                <Modal.Body>
                    <Carousel activeIndex={galleryIndex} onSelect={index => setGalleryIndex(index)} className="h-100">
                        {
                            gallery.map(image => (
                                <Carousel.Item key={image.id} className="h-100">
                                    <img
                                        src={image.url}
                                        alt={image.filename}
                                    />
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default function Product() {
    return (
        <EditorPage
            entity={ENTITY}
            renderContentFunction={PageContent}
        />
    );
};
