import { useState } from "react";
import { Link, useRevalidator } from "react-router-dom";

import Table from 'react-bootstrap/Table';
import ListPage, { get_page_state } from './ListPage';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import {
    load_records,
    load_record,
    save_record
} from "../services/ApiService";

const PAGE_STATE_ID = "user-state";
const page_state = get_page_state(PAGE_STATE_ID);

export async function loader({ request }) {
    const url = new URL(request.url);
    const query = url.searchParams.get("q")
    let response = await load_records(query ? "" : page_state.current_offset, query || page_state.query, "users");
    if(!!response && !!response.error) response = await load_records("", query || page_state.query, "users");
    return response;
}

const PageContent = function({ records, linkIdBuilder, onView }) {
    return (
        <>
            <Table striped>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        records.map(user => (
                            <tr key={user.id} onClick={() => onView(user)}>
                                <td>
                                    <Link id={linkIdBuilder(user)} to={`/user/${user.id}`}>{user.username}</Link>
                                </td>
                                <td>
                                    <a href={`mailto:${user.email}`} onClick={event => event.stopPropagation()}>{user.email}</a>
                                </td>
                                <td>{(user.role === 10) ? "Administrator" : "User"}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    );
};

const CONSTANTS = require("../constants.js");

const InviteUserDialog = function({ show, onHide }) {
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState(null);
	const [saving, setSaving] = useState(false);

    const reset_shell_record = () => {
        setLoading(true);
        load_record('', 'user').then(response => {
            const record = response || {};
            setUser(record);
            setLoading(false);
        });
    }
    const change_field_value = (event) => {
        const value = event.target.value
        const field = event.target.name
        if(user) {
            const record = {
                ...user,
                [field]: value
            };
            setUser(record);
        }
    }
    const create = () => {
        setSaving(true);
        save_record("", user, "user").then(record => {
            if(record && record.id) {
                alert("Invitation email sent!");
                onHide();
            }
        });
    }

    return (
        <Modal
            show={show}
            onShow={reset_shell_record}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Invite a new user
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {
                    (!!loading && <Spinner animation="grow" />) ||
                    (
                        !loading &&
                        !!user &&
                        <div>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="username">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                            name="username"
                                            value={user.username || ''}
                                            onChange={change_field_value}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="username">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={user.email || ''}
                                            onChange={change_field_value}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="role" className="mt-2">
                                        <Form.Label>Role</Form.Label>
                                        <Form.Select
                                            name="role"
                                            value={user.role}
                                            onChange={change_field_value}
                                            required
                                        >
                                            {
                                                CONSTANTS.USER_ROLES.map(role => (
                                                    <option
                                                        value={role.id}
                                                        key={role.id}
                                                    >
                                                        {role.name}
                                                    </option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="invitation_expires_in" className="mt-2">
                                        <Form.Label>Invitation Expires In</Form.Label>
                                        <Form.Select
                                            name="invitation_expires_in"
                                            value={user.invitation_expires_in}
                                            onChange={change_field_value}
                                            required
                                        >
                                            {
                                                CONSTANTS.INVITATION_DELAYS.map(delay => (
                                                    <option
                                                        value={delay.id}
                                                        key={delay.id}
                                                    >
                                                        {delay.name}
                                                    </option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    )
                }
            </Modal.Body>

            {
                !loading &&
                !!user &&
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        disabled={saving}
                        onClick={onHide}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        disabled={saving || !user.username || !user.email}
                        onClick={create}
                    >
                        Send Invitation
                    </Button>
                </Modal.Footer>
            }
        </Modal>
    );
};

export default function Users() {
    const revalidator = useRevalidator();
	const [inviting, setInviting] = useState(false);

    const invitation_done = () => {
        revalidator.revalidate();
        setInviting(false);
    };
        
    return (
        <div>
            <ListPage
                title="Users"
                pageStateId={PAGE_STATE_ID}
                pageState={page_state}
                onAdd={() => setInviting(true)}
                renderContentFunction={PageContent}
            />
            <InviteUserDialog
                show={inviting}
                onHide={invitation_done}
            />
        </div>
    );
};
