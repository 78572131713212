import { FileUploader } from "react-drag-drop-files";

const IMAGE_TYPES = ["JPG", "JPEG", "PNG"];
const MAX_SIZE = 5 * 1024; // in MB

function ImageUpload(props) {

  let { types, maxSize, ...custom_props } = props

  return (
    <FileUploader
      {...custom_props}
      types={IMAGE_TYPES}
      maxSize={MAX_SIZE}
    />
  );
}

export default ImageUpload;
