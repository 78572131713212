import { useState, useEffect } from "react";
import { useOutletContext, useLoaderData } from "react-router-dom";

import {
    load_records
} from "../services/ApiService";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from "react-bootstrap/Spinner";

export function get_page_state(PAGE_STATE_ID) {
    const default_state = {
        expires_at: 0,
        current_offset: "",
        next_offset: "",
        query: ""
    };
    const loaded_state = JSON.parse(localStorage.getItem(PAGE_STATE_ID) || "{}");
    if(!!loaded_state.expires_at && ((new Date()).getTime() <= loaded_state.expires_at)) {
        return {
            ...default_state,
            ...loaded_state
        };
    }
    return default_state;
}
export function set_page_state(PAGE_STATE_ID, pageState, new_state) {
    Object.keys(new_state).forEach(key => pageState[key] = new_state[key])
    const now = new Date();
    now.setHours(now.getHours() + 1);
    pageState.expires_at = now.getTime();
    localStorage.setItem(PAGE_STATE_ID, JSON.stringify(pageState));
}

export default function ListPage({ pageState, pageStateId, title, onAdd, renderContentFunction }) {
    const [loading, setLoading] = useOutletContext();
	const [pageRecords, setPageRecords] = useState([]);
    const { records, offset } = useLoaderData();

    useEffect(() => {
        setPageRecords(records);
        set_page_state(pageStateId, pageState, { next_offset: offset });
    }, [records, offset, setPageRecords, pageStateId, pageState]);

    const load_page_records = (offset, query) => {
        setLoading(true);
        load_records(offset, query).then(response => {
            set_page_state(pageStateId, pageState, { query, current_offset: offset, offset: response.offset });
            setPageRecords(response.records);
            setLoading(false);
            window.scrollTo(0, 0);
        });
    }

    const goto_page = (offset = "") => {
        load_page_records(offset, pageState.query);
    }
    const link_id_for_page_record = record => {
        return `record-view-link-${record.id}`
    }
    const view = record => {
        const node = document.getElementById(link_id_for_page_record(record))
        if(node) node.click()
    }

    return (
        loading ?
        <Spinner animation="grow" /> :
        <>
            <h1>
                { title }
                {
                    onAdd ?
                    <Button
                        size="sm"
                        className="ms-2"
                        onClick={onAdd}
                    >
                        +
                    </Button> :
                    <></>
                }
            </h1>
            <Container>
                { renderContentFunction({ records: pageRecords, linkIdBuilder: link_id_for_page_record, onView: view }) }
            </Container>
            <Container>
                <Row>
                    <Col>
                        {!!pageState.current_offset && <Button variant="primary" onClick={() => goto_page("")}>First Page</Button>}
                    </Col>
                    <Col className="text-end">
                        {pageState.next_offset && <Button variant="primary" onClick={() => goto_page(pageState.next_offset)}>Next Page</Button>}
                    </Col>
                </Row>
            </Container>
        </>
    );
};
